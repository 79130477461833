import accuracyComparator from '../comparator/gridCompareAccuracy'

const stratHumanAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const stratHumanReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const stratHumanRiskReward1 = {
  colId: 'RiskReward1',
  headerName: 'Risk/Reward',
  cellRendererFramework: 'riskReward',
  field: 'reward',
  flex: 1,
}
const stratHumanLink = {
  colId: 'StrategyLink1',
  headerName: 'More info',
  field: 'instrument',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'strategyInfoLink',
  cellStyle: () => ({ cursor: 'pointer' }),
}
const stratHumanInstrumentStrat = {
  colId: 'InstrumentStrategy1',
  headerName: 'Type',
  field: 'instrument',
  cellRendererFramework: 'instrumentStratIndicators',
  flex: 1,
  filter: false,
}
const stratHumanFrequency = {
  colId: 'Frequency1',
  headerName: 'Frequency',
  field: 'frequency',
  cellRendererFramework: 'frequency',
  flex: 1,
}

// const stratHumanFollow = {
//   colId: 'FollowStrategy1',
//   headerName: 'Follow Strategy',
//   field: 'id',
//   flex: 1,
//   resizable: false,
//   cellRendererFramework: 'followUnfollowStrat',
//   cellStyle: () => ({ cursor: 'pointer' }),
// }

const stratHumanColumns = [
  stratHumanInstrumentStrat,
  stratHumanFrequency,
  stratHumanAccuracy,
  stratHumanReturns,
  stratHumanRiskReward1,
  stratHumanLink,
  // stratHumanFollow,
]

export default stratHumanColumns
