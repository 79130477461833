import dateFilterParams from '../filters/gridFilterDate'
import accuracyComparator from '../comparator/gridCompareAccuracy'

const positionsEntryTime = {
  colId: 'EntryTimeJournal1',
  headerName: 'Date/Time',
  field: 'journalCreatedAt',
  filter: 'agDateColumnFilter',
  filterParams: dateFilterParams,
  cellRendererFramework: 'genericDateTime',
  flex: 1,
  sort: 'desc',
}

const positionsInstrument = {
  colId: 'JournalInstrumentStratAction1',
  headerName: 'Instrument',
  field: 'instrument',
  cellRendererFramework: 'genericInstrument',
  flex: 1,
  filter: false,
}

const positionsAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const positionsReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const positionsEntryPrice1 = {
  colId: 'JournalEntryPrice1',
  headerName: 'Entry',
  field: 'entryPrice',
  flex: 1,
  cellRenderer: 'journalEntryPrice',
  filter: true,
}

const positionsSL = {
  colId: 'PositionsSL1',
  headerName: 'SL',
  field: 'sl',
  flex: 1,
  cellRendererFramework: 'positionsSL',
  filter: true,
}

const positionsTP = {
  colId: 'PositionsTP1',
  headerName: 'TP',
  field: 'tp',
  flex: 1,
  cellRendererFramework: 'positionsTP',
  filter: true,
}

const positionsReturn = {
  colId: 'JournalResult1',
  headerName: 'Profit',
  field: 'result',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'positionsResult',
}

const positionsCloseTrade = {
  colId: 'positionsCloseTrade1',
  headerName: 'Close Position',
  field: 'result',
  flex: 1.5,
  resizable: false,
  cellRendererFramework: 'positionsCloseTrade',
  cellStyle: () => ({ cursor: 'pointer' }),
}

const positionsColumns = [
  positionsEntryTime,
  positionsInstrument,
  positionsAccuracy,
  positionsReturns,
  positionsEntryPrice1,
  positionsSL,
  positionsTP,
  positionsReturn,
  positionsCloseTrade,
]

export default positionsColumns
