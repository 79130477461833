<template>
  <div
    v-if="params.data"
    class="d-flex flex-row align-items-center"
  >
    <p
      class="action plot"
      @click="plotTrades(params.data)"
    >
      Plot
    </p>
  </div>
</template>

<script>

import useTrades from '@/composables/trades/useTrades'

export default {
  name: 'RendStrategiesPlot',
  setup() {
    const { setTestingTrades } = useTrades()
    return {
      setTestingTrades,
    }
  },
  data() {
    return {
      params: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.strategiesPlotRend(this.params)
  },
  methods: {
    strategiesPlotRend(params) {
      this.params = params
    },
    plotTrades(data) {
      this.setTestingTrades()
      this.$router.push({
        name: 'plotTrade',
        params: {
          tradeId: data.id,
          initialTrade: data,
          initialType: 'strategies',
        },
      })
    },
  },
}
</script>

<style>
</style>
