function accuracyComparator(accuracy1, accuracy2) {
  const accuracy1Total = accuracy1
  const accuracy2Total = accuracy2
  if (accuracy1Total === null && accuracy2Total === null) {
    return 0
  }
  if (accuracy1Total === null) {
    return -1
  }
  if (accuracy2Total === null) {
    return 1
  }
  return accuracy1Total - accuracy2Total
}

export default accuracyComparator
