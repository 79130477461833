<template>
  <span class="cell-strategy ${params.value}">{{ strategy }}</span>
</template>

<script>
export default {
  name: 'SigStrategy',
  data() {
    return {
      strategy: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.strategyRenderer(this.params)
  },
  methods: {
    strategyRenderer(params) {
      this.strategy = params.value
    },
  },
}
</script>

<style>

</style>
