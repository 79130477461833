<template>
  <div class="d-flex flex-column">
    <span class="cell-line-one"> {{ impact }}</span>
  </div>
</template>

<script>
export default {
  name: 'NewsImpact',
  data() {
    return {
      impact: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.newsImpact(this.params)
  },
  methods: {
    newsImpact(params) {
      this.impact = params.data.impact
    },
  },
}
</script>

<style>

</style>
