// import router from '@/router'

import dateFilterParams from '../filters/gridFilterDate'
import accuracyComparator from '../comparator/gridCompareAccuracy'

function journalTpRenderer(params) {
  const { tp, pipsTP, pipsSL } = params.data
  const reward = Math.abs(pipsTP.user / pipsSL.user).toFixed(1)
  return `
      <div class="d-flex flex-column">
        <span class="cell-line-one" >
          <span class="green-text" >${tp}</span>
          <i class="far fa-copy" id="copy" data-clipboard-text="${tp}" ></i>
        </span>
        <span class="cell-line-two" >${pipsTP.user} PIPs (${reward}x risk) </span>
      </div>
    `
}

function journalSlRenderer(params) {
  const { sl, pipsSL } = params.data
  return `
    <div class="d-flex flex-column">
      <span class="cell-line-one" >
        <span class="red-text">${sl}</span>
        <i class="far fa-copy" id="copy" data-clipboard-text="${sl}" ></i>
      </span>
      <span class="cell-line-two" >${pipsSL.user} PIPs</span>
    </div>
  `
}

const journalEntryTime = {
  colId: 'EntryTimeJournal1',
  headerName: 'Date/Time',
  field: 'journalCreatedAt',
  filter: 'agDateColumnFilter',
  filterParams: dateFilterParams,
  cellRendererFramework: 'genericDateTime',
  flex: 1,
  sort: 'desc',
}

const journalInstrument = {
  colId: 'JournalInstrument1',
  headerName: 'Pairing',
  field: 'instrument',
  cellRendererFramework: 'genericInstrument',
  flex: 1,
  minWidth: 140,
  filter: false,
}

// const journalType = {
//   colId: 'JournalType1',
//   headerName: 'Type',
//   field: 'strategyType',
//   cellRendererFramework: 'genericType',
//   flex: 1,
//   filter: false,
// }

const journalAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const journalReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const journalEntryPrice = {
  colId: 'JournalEntryPrice1',
  headerName: 'Entry',
  field: 'entryPrice',
  flex: 1,
  cellRendererFramework: 'journalEntryPrice',
  filter: true,
}

const journalSL = {
  colId: 'JournalSL1',
  headerName: 'SL',
  field: 'sl',
  flex: 1,
  cellRenderer: journalSlRenderer,
  filter: true,
}

const journalTP = {
  colId: 'JournalTP1',
  headerName: 'TP',
  field: 'tp',
  flex: 1,
  cellRenderer: journalTpRenderer,
  filter: true,
}

const journalResult = {
  colId: 'JournalResult1',
  headerName: 'Result',
  field: 'result',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'rendJournalResult',
}

const journalPlot = {
  colId: 'JournalPlot1',
  headerName: 'Plot',
  field: 'result',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'journalPlotTrade',
  cellStyle: () => ({ cursor: 'pointer' }),
}

const journalColumns = [
  journalEntryTime,
  journalInstrument,
  // journalType,
  journalAccuracy,
  journalReturns,
  journalEntryPrice,
  journalSL,
  journalTP,
  journalResult,
  journalPlot,
]

export default journalColumns
