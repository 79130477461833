<template>
  <div class="d-flex flex-column">
    <span :class="'cell-strategy ' + strategyType + ' analysis'"> {{ strategyType }}</span>
  </div>
</template>

<script>
export default {
  name: 'RendGenericType',
  data() {
    return {
      strategyType: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.genericTypeRenderer(this.params)
  },
  methods: {
    genericTypeRenderer(params) {
      this.strategyType = params.data.strategyType
    },
  },
}
</script>

<style>

</style>
