// for plotting Hermes datasets on the chart
// make sure you understand HTML5 canvas when editing this file

import { Overlay } from 'trading-vue-js'

export default {
  name: 'plotHermesDatasetsOverlay',
  mixins: [Overlay],
  methods: {
    draw(ctx) {
      const context = ctx
      const { state } = this.$store
      const { layout } = this.$props // Layout object (see trading vue API BOOK)
      // // setup
      // // colors
      let creationColor = 'rgba(39, 63, 245, 0.08)'
      let trainingColor = 'rgba(245, 39, 243, 0.08)'
      // let defaultBackground = 'hsla(0, 0%, 100%, 0.6)'
      let defaultStroke = '#D3D3D3'
      if (this.isDark) {
        creationColor = 'rgba(39, 63, 245, 0.08)'
        trainingColor = 'rgba(245, 39, 243, 0.08)'
        // defaultBackground = 'hsla(0, 0%, 100%, 0.08)'
        defaultStroke = '#4a4a4a'
      }
      // historic trades
      // eslint-disable-next-line arrow-body-style
      if (state.single.strategy.data && state.activeStrategyType === 'Hermes') {
        // const strategy = state.single.strategy.data
        const {
          creationBeginTime,
          creationEndTime,
          beginTime,
          endTime,
        } = state.single.strategy.data

        const localDate = new Date()
        const timeOffset = localDate.getTimezoneOffset() * 60000
        const creationDatasetStart = (creationBeginTime / 1000) - timeOffset
        const creationDatasetEnd = (creationEndTime / 1000) - timeOffset
        const trainingDatasetStart = (beginTime / 1000) - timeOffset
        const trainingDatasetEnd = (endTime / 1000) - timeOffset
        // find price at dataset points
        // mapping times
        const creationDatasetStartX = layout.t2screen(creationDatasetStart)
        const creationDatasetEndX = layout.t2screen(creationDatasetEnd)
        const trainingDatasetStartX = layout.t2screen(trainingDatasetStart)
        const trainingDatasetEndX = layout.t2screen(trainingDatasetEnd)
        // mapping prices
        const creationDatasetStartY = layout.$2screen(0)
        const creationDatasetEndY = layout.$2screen(1000)
        const trainingDatasetStartY = layout.$2screen(0)
        const trainingDatasetEndY = layout.$2screen(1000)

        // creation dataset
        context.beginPath()
        context.fillStyle = creationColor
        context.strokeStyle = defaultStroke
        context.fillRect(creationDatasetStartX, creationDatasetStartY, -(creationDatasetStartX - creationDatasetEndX), -(creationDatasetStartY - creationDatasetEndY))
        // training dataset
        context.beginPath()
        context.fillStyle = trainingColor
        context.strokeStyle = defaultStroke
        context.fillRect(trainingDatasetStartX, trainingDatasetStartY, -(trainingDatasetStartX - trainingDatasetEndX), -(trainingDatasetStartY - trainingDatasetEndY))
      }
    },
    use_for() { return ['plotHermesDatasetsOverlay'] },
  },
  // Define internal setting & constants here
  computed: {
    isDark() {
      return this.$props.settings.isDark
    },
  },
}
