<template>
  <div class="percent-bar-wrap">
    <div
      :id="barColor"
      class="percent-bar"
      :style="{ width: accuracy + '%' }"
    >
      <span class="percent-bar-value">{{ accuracy }}% of {{ totalTrades.toFixed(0) }} trades</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccuracyTotalTrades',
  data() {
    return {
      accessToSignal: false,
      accuracy: null,
      barColor: null,
      avgReturn: null,
      totalTrades: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.accuracyTotalTradesRenderer(this.params)
  },
  methods: {
    accuracyTotalTradesRenderer(params) {
      this.accuracy = params.data.accuracy || 0
      this.totalTrades = params.data.totalTrades
      this.avgReturn = params.data.avgReturn
      if (this.avgReturn < -0.15) {
        this.barColor = 'percentBarRed'
      } else if (this.avgReturn < 0) {
        this.barColor = 'percentBarOrange'
      } else {
        this.barColor = 'percentBarGreen'
      }
    },
  },
}
</script>

<style>

</style>
