import {
  ref,
  computed,
  // readonly,
} from '@vue/composition-api'

const clock = ref({
  now: null,
  countdown: {
    days: 0,
    minutes: 0,
    hours: 0,
    seconds: 0,
  },
  countup: {
    days: 0,
    minutes: 0,
    hours: 0,
    seconds: 0,
  },
  time: Date,
})

export default function useClock() {
  //
  function setClockTime() {
    const d = new Date()
    clock.value.time = d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    })
  }

  function setCountup({ time, type }) {
    const now = new Date().getTime()
    let sinceCreated
    if (time.getTime) {
      const t = time.getTime()
      sinceCreated = now - t
    } else {
      sinceCreated = now - time
    }
    const ageInSeconds = Math.round(sinceCreated / 1000)
    const hours = Math.abs(Math.trunc((ageInSeconds / 60) / 60))
    const minutes = String(Math.abs(Math.trunc((ageInSeconds / 60) % 60))).padStart(2, 0)
    const seconds = String(Math.abs(ageInSeconds % 60)).padStart(2, 0)
    let format
    if (type === 'minute') format = `${hours}h${minutes}m`
    else if (type === 'second') format = `${hours}:${minutes}:${seconds}`
    return format
  }

  function setCountdown({ time }) {
    const now = new Date().getTime()
    const timeSinceCreated = now - time
    const ageInSeconds = Math.round(timeSinceCreated / 1000)
    const hours = Math.trunc((ageInSeconds / 60) / 60)
    const minutes = String(Math.trunc((ageInSeconds / 60) % 60)).padStart(2, 0)
    const seconds = String(ageInSeconds % 60).padStart(2, 0)
    return `${hours}:${minutes}:${seconds}`
  }

  return {
    clockTime: computed(() => clock.value.time),
    // timestampNow: computed(() => now()),
    setClockTime,
    setCountup,
    setCountdown,
  }
}
