<template>
  <div class="d-flex flex-column">
    <span :class="'cell-line-one ' + textColor">Avg: {{ avgReturn.toFixed(2) }}%</span>
    <span
      :class="'cell-line-two ' + textColor"
    >Total: {{ totalReturn.toFixed(2) }}%</span>
  </div>
</template>

<script>
export default {
  name: 'Returns',
  data() {
    return {
      avgReturn: null,
      totalReturn: null,
      textColor: null,
      page: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.returnsData(this.params)
  },
  methods: {
    returnsData(params) {
      this.page = this.$route.name
      this.avgReturn = params.data.avgReturn
      this.totalReturn = params.data.totalReturn
      if (this.avgReturn > 0) {
        this.textColor = 'green-text'
      } else {
        this.textColor = 'red-text'
      }
    },
  },
}
</script>

<style>

</style>
