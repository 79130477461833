import accuracyComparator from '../comparator/gridCompareAccuracy'
import dateFilterParams from '../filters/gridFilterDate'

function activationRenderer(params) {
  const { activation, avgActivation } = params.data
  return `
    <div class="d-flex flex-column">
      <span class="cell-multi-line bold"> Signal: ${activation.toFixed(
    2,
  )}%</span>
      <span class="cell-line-two" >Avg: ${avgActivation.toFixed(2)}%</span>
    </div>
  `
}

function entrySlTpRenderer(params) {
  const { entry, sl, tp } = params.value
  return `
    <div class="d-flex flex-column">
      <span class="cell-multi-line bold green-text" >${tp}</span>
      <span class="cell-multi-line" >${entry}</span>
      <span class="cell-multi-line bold red-text" >${sl}</span>
    </div>
  `
}

const analysisHeremesEntryTime = {
  colId: 'EntryTimeJournal1',
  headerName: 'Date/Time',
  field: 'journalCreatedAt',
  filter: 'agDateColumnFilter',
  filterParams: dateFilterParams,
  cellRendererFramework: 'genericDateTime',
  flex: 1,
  sort: 'desc',
}

const analysisHermesInstrument = {
  colId: 'JournalInstrumentStratAction1',
  headerName: 'Type',
  field: 'instrument',
  cellRendererFramework: 'genericInstrument',
  minWidth: 140,
  flex: 1,
  filter: false,
}

const analysisHermesAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const analysisHermesReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const analysisHermesEntrySlTp = {
  colId: 'EntrySlTp1',
  headerName: 'TP/Entry/SL',
  cellRenderer: entrySlTpRenderer,
  sortable: false,
  valueGetter: params => ({
    entry: params.data.entryPrice,
    sl: params.data.sl,
    tp: params.data.tp,
  }),
  flex: 1,
}

const analysisHermesActivation = {
  colId: 'Activation1',
  headerName: 'Activation',
  cellRenderer: activationRenderer,
  field: 'activation',
  flex: 1,
}

const analysisHermesRiskReward = {
  colId: 'RiskReward1',
  headerName: 'Risk/Reward',
  cellRendererFramework: 'riskReward',
  field: 'reward',
  flex: 1,
}

const analysisHermesResultPlot = {
  colId: 'PlotResult1',
  headerName: 'Plot/Result',
  field: 'result.signal',
  flex: 1,
  minWidth: 140,
  resizable: false,
  cellRendererFramework: 'analysisPlot',
}

const analysisHermesColumns = [
  analysisHeremesEntryTime,
  analysisHermesInstrument,
  analysisHermesAccuracy,
  analysisHermesReturns,
  analysisHermesEntrySlTp,
  analysisHermesRiskReward,
  analysisHermesActivation,
  analysisHermesResultPlot,
]

export default analysisHermesColumns
