<template>
  <div>
    <div
      v-if="indicators != null"
      class="d-flex flex-column"
    >
      <span :class="'cell-line-two ' + strategyType.toLowerCase()">{{ strategyType.toUpperCase() }} - {{ instrument }}</span>
      <span class="cell-line-two">{{ indicators }}</span>
    </div>
    <div
      v-if="page !== 'strategies'"
      class="d-flex flex-column"
    >
      <span :class="'cell-line-two ' + strategyType.toLowerCase()">{{ instrument }}</span>
      <span class="cell-line-two"> {{ strategyType }} - {{ action }}</span>
    </div>
    <div
      v-if="page === 'strategies' && strategyType === 'hermes'"
      class="d-flex flex-column"
    >
      <span :class="'cell-line-two ' + strategyType.toLowerCase()">{{ strategyType.toUpperCase() }} - {{ instrument }}</span>
      <span class="cell-line-two">Training metrics</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstrumentStratIndicators',
  data() {
    return {
      strategyType: null,
      instrument: null,
      indicators: null,
      action: null,
      page: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.instrumentStrategyIndicators(this.params)
  },
  methods: {
    instrumentStrategyIndicators(params) {
      this.strategyType = params.data.strategyType
      this.instrument = params.data.instrument.replace('_', '/').replace(' ', '')
      this.indicators = params.data.indicators
      this.action = params.data.action
      this.page = this.$route.name
      this.$log('info', 'instrumentStrategyIndicators', this.page, this.strategyType)
    },
  },
}
</script>

<style>

</style>
