<template>
  <div class="d-flex flex-column">
    <span :class="'cell-line-one'">{{ buys }} / {{ sells }}</span>
  </div>
</template>

<script>
export default {
  name: 'RendPerformanceBuySell',
  data() {
    return {
      buys: null,
      sells: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.buySellRend(this.params)
  },
  methods: {
    buySellRend(params) {
      this.buys = params.data.buys
      this.sells = params.data.sells
      // if (this.avgReturn > 0) {
      //   this.textColor = 'green-text'
      // } else {
      //   this.textColor = 'red-text'
      // }
    },
  },
}
</script>

<style>

</style>
