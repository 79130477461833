<template>
  <div class="d-flex flex-column">
    <span class="cell-multi-line bold">1 / {{ reward.toFixed(2) }}%</span>
    <span
      v-if="pipsSL"
      class="cell-line-two"
    >{{ pipsSL }} / {{ pipsTP }} PIPs</span>
  </div>
</template>

<script>
export default {
  name: 'RendGenericRiskReward',
  data() {
    return {
      pipsSL: null,
      pipsTP: null,
      reward: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.riskRewardPercentPips(this.params)
  },
  methods: {
    riskRewardPercentPips(params) {
      this.pipsSL = params.data.pipsSL || null
      this.pipsTP = params.data.pipsTP || null
      this.reward = params.data.reward || params.data.avgReward
    },
  },
}
</script>

<style>

</style>
