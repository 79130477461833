<template>
  <div
    ref="sigInst"
    @click="setInstrumentActive({ newInst: { name: instrument } })"
  >
    <div
      v-if="instrument !== null"
      class="d-flex flex-column"
    >
      <span class="cell-line-one bold">{{ instrument.replace('_', '/') }}</span>
      <span class="cell-line-two">{{ signalTime }} ago</span>
    </div>
    <div
      v-else
      class="d-flex flex-column"
    >
      <span class="cell-line-one">???</span>
      <span class="cell-line-two">{{ signalTime }} ago</span>
    </div>
  </div>
</template>

<script>
import useInstruments from '@/composables/useInstruments'
import useClock from '@/composables/useClock'
import usePermissions from '@/composables/usePermissions'

export default {
  name: 'SigInstrumentTime',
  // eslint-disable-next-line object-curly-newline
  setup() {
    const { setInstrumentActive } = useInstruments()
    const { access } = usePermissions()
    const { setCountup } = useClock()

    return {
      setInstrumentActive,
      access,
      setCountup,
    }
  },
  data() {
    return {
      instrument: null,
      signalTime: null,
      creationInterval: null,
      // minutes: null,
      // seconds: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.sigPairingRenderer(this.params)
    this.signalTime = this.setCountup({ time: this.params.data.signalTime, type: 'second' })
    this.creationInterval = setInterval(() => {
      this.signalTime = this.setCountup({ time: this.params.data.signalTime, type: 'second' })
    }, 1000)
  },
  methods: {
    sigPairingRenderer(params) {
      const access = this.access.signal({ strategy: params.data.strategyType })
      if (access) {
        this.instrument = params.data.instrument
      }
    },
  },
}
</script>

<style>
</style>
