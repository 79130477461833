<template>
  <div class="d-flex flex-column ml-1">
    <span class="cell-line-one">Total Return: {{ totalRet }}%</span>
    <span class="cell-line-two">Avg. Retrun: {{ avgRet }}%</span>
  </div>
</template>

<script>
export default {
  name: 'LeaderboardPrevMonthRets',
  data() {
    return {
      totalRet: 0,
      avgRet: 0,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.leaderboardLastMonthRets(this.params)
  },
  methods: {
    leaderboardLastMonthRets(params) {
      if (params.data.prevMonth !== null) {
        this.totalRet = params.data.prevMonth.totalReturn
        this.avgRet = params.data.prevMonth.avgReturn
      }
    },
  },
}
</script>

<style>

</style>
