import {
  ref,
  computed,
} from '@vue/composition-api'
import useUserSingle from './users/useUserSingle'

const usage = ref({
  takeTrade: 0,
})

export default function usePermissions() {
  const { user } = useUserSingle()
  // async function checkUsage() {
  //   let data
  //   if (usage.value.trades > 0) {
  //     data = true
  //   } else {
  //     // get usage from DB
  //     data = true
  //   }
  //   return data
  // }
  // check if user has exceeded limits for their permission
  async function paramsCheck(feature) {
    // const haveUsage = await checkUsage()
    const params = {
      Silver: {
        takeTrade: 100,
      },
      gold: {
        takeTrade: 1000,
      },
    }
    let ability = false
    if (usage.value[feature] < params[user.value.plan][feature]) {
      ability = true
    }
    return ability
  }

  function featureCheck(requestedFeature) {
    // list out features, they should cascade
    const silverFeatures = ['takeTrade']
    const goldFeatures = [...silverFeatures, 'blogs']
    const adminFeatures = [...goldFeatures, 'metrics']

    let ability = false
    if (silverFeatures.includes(requestedFeature)) {
      ability = true
    } else if (goldFeatures.includes(requestedFeature) && user.value.plan === 'gold') {
      ability = true
    } else if (adminFeatures.includes(requestedFeature) && user.value.role === 'admin') {
      ability = true
    }
    return ability
  }

  function signalAccess(stratType) {
    let ability = false
    if (stratType !== 'Hermes') {
      ability = true
    } else if (user.value.plan !== 'silver' && stratType === 'Hermes') {
      ability = true
    }
    return ability
  }

  function contentAccess(requestedContent) {
    let ability
    // feature limits by permission
    const silverContent = ['silverShit']
    const goldContent = [...silverContent, 'profileJournal']
    const adminContent = [...goldContent, 'metrics']

    if (silverContent.includes(requestedContent)) {
      ability = true
    } else if (goldContent.includes(requestedContent) && user.value.plan === 'gold') {
      ability = true
    } else if (adminContent.includes(requestedContent) && user.value.role === 'admin') {
      ability = true
    } else {
      ability = false
    }
    return ability
  }

  async function featureAccess(feature) {
    let ability = false
    // first check that user has access to feature
    if (featureCheck(feature)) {
      // then check if they have exceeded their limits
      ability = await paramsCheck(feature)
    }
    return ability
  }

  return {
    usage: computed(() => usage.value),
    access: {
      feature: featureAccess,
      content: contentAccess,
      signal: signalAccess,
    },
  }
}
