<template>
  <div class="percent-bar-wrap">
    <div
      :id="barColor"
      class="percent-bar"
      :style="{ width: accuracy + '%' }"
    >
      <span class="percent-bar-value">{{ accuracy }}% of {{ totalTrades }} trades</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeaderboardAccuracyTotalTrades',
  data() {
    return {
      accuracy: 0,
      totalTrades: 0,
      barColor: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.leaderboardAccuracyTotalTradesRenderer(this.params)
  },
  methods: {
    leaderboardAccuracyTotalTradesRenderer(params) {
      this.accuracy = Number(params.data.currentMonth.accuracy) || 0
      this.totalTrades = Number(params.data.currentMonth.totalTrades) || 0
      this.barColor = params.data.accuracyBarColor
    },
  },
}
</script>

<style>

</style>
