<template>
  <div class="d-flex flex-column">
    <span class="cell-line-one"> {{ title }}</span>
    <span class="cell-line-two">{{ pair }}</span>
  </div>
</template>

<script>
export default {
  name: 'NewsTitlePair',
  data() {
    return {
      title: null,
      pair: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.newsTitlePairRenderer(this.params)
  },
  methods: {
    newsTitlePairRenderer(params) {
      this.title = params.data.title
      this.pair = params.data.pair
    },
  },
}
</script>

<style>

</style>
