import accuracyComparator from '../comparator/gridCompareAccuracy'

const sigInstrumentAge = {
  colId: 'InstrumentAge1', // For choosing which columns are displayed, must be unique and not match header etc
  headerName: 'Pairing', // Title on grid
  field: 'signalTime', // links to data
  flex: 1,
  filter: true,
  cellRendererFramework: 'sigInstrumentTime', // for custom styling
  sort: 'desc', // order by newest first
  cellStyle: () => ({ cursor: 'pointer' }),
}

// const sigStrategy = {
//   colId: 'Strategy1',
//   headerName: 'Strategy',
//   field: 'strategyType',
//   cellRendererFramework: 'sigStrategy',
//   flex: 1,
// }

const sigAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const sigReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const sigEntryPrice = {
  colId: 'SigEntryPrice1',
  headerName: 'Entry / Current',
  field: 'sigEntryPrice',
  flex: 1,
  cellRendererFramework: 'sigEntry',
  filter: true,
}

const sigSL = {
  colId: 'SigSL1',
  headerName: 'SL',
  field: 'sigSL',
  flex: 1,
  cellRendererFramework: 'sigSl',
  filter: true,
}

const sigTP = {
  colId: 'SigTP1',
  headerName: 'TP',
  field: 'sigTP',
  flex: 1,
  cellRendererFramework: 'sigTp',
  filter: true,
}

const sigAction = {
  colId: 'SigAction1',
  headerName: 'Action',
  field: 'action',
  flex: 1,
  minWidth: 140,
  cellRendererFramework: 'sigAction',
  cellStyle: () => ({ cursor: 'pointer' }),
  filter: true,
}

const signalColumns = [
  sigInstrumentAge,
  // sigStrategy,
  sigAccuracy,
  sigReturns,
  sigEntryPrice,
  sigSL,
  sigTP,
  sigAction,
]

export default signalColumns
