<template>
  <div class="d-flex flex-column">
    <span class="cell-line-one"> {{ date }}</span>
    <span class="cell-line-two">{{ time.substring(11, 23) }}</span>
  </div>
</template>

<script>
export default {
  name: 'NewsDateTime',
  data() {
    return {
      date: null,
      time: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.newsDateTimeRenderer(this.params)
  },
  methods: {
    newsDateTimeRenderer(params) {
      this.date = params.data.date.toDateString()
      this.time = params.data.date.toLocaleString()
    },
  },
}
</script>

<style>

</style>
