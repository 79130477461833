import accuracyComparator from '../comparator/gridCompareAccuracy'

function avgTpSlRenderer(params) {
  const { pipsTP, pipsSL } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two hermes" >${pipsSL} / ${pipsTP} PIPs</span>
    <span class="cell-line-two" >1 / ${(pipsTP / pipsSL).toFixed(2)}%</span>
  </div>
  `
}

function hermesActivation(params) {
  const { avgActivation, stdevActivation } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two" >Avg: ${avgActivation.toFixed(2)}%</span>
    <span class="cell-line-two" >Stdev: ${stdevActivation.toFixed(2)}%</span>
  </div>
  `
}

function hermesReturnRenderer(params) {
  const { avgReturn, totalReturn } = params.data
  let textColor = ''
  if (avgReturn > 0) {
    textColor = 'green-text'
  } else {
    textColor = 'red-text'
  }
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two ${textColor}" >Avg. Ret: ${avgReturn.toFixed(2)}%</span>
    <span class="cell-line-two ${textColor}" >Total Ret: ${totalReturn.toFixed(2)}%</span>
  </div>
  `
}

function evolutionRenderer(params) {
  const { evolved, iterations } = params.data
  return `
    <div class="d-flex flex-column">
      <span class="cell-multi-line bold" >evolved ${evolved} times</span>
      <span class="cell-line-two" >from ${iterations} cycles</span>
    </div>
  `
}

const stratHybridAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const stratHybridReturn = {
  colId: 'HermesReturn1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRenderer: hermesReturnRenderer,
}

const stratHybridEvolution = {
  colId: 'Evolution1',
  headerName: 'Evolved',
  field: 'evolved',
  cellRenderer: evolutionRenderer,
  flex: 1,
}

const stratHybridAvgRR = {
  colId: 'AvgRR1',
  headerName: 'Avg. RR',
  field: 'avgTP',
  cellRenderer: avgTpSlRenderer,
  flex: 1,
}

const stratHybridActivation = {
  colId: 'HermesActivation1',
  headerName: 'Activation',
  field: 'avgActivation',
  cellRenderer: hermesActivation,
  flex: 1,
}

const stratHybridInstrumentStrat = {
  colId: 'InstrumentStrategy1',
  headerName: 'Type',
  field: 'instrument',
  cellRendererFramework: 'instrumentStratIndicators',
  flex: 1,
  filter: false,
}

const stratHybridFrequency = {
  colId: 'Frequency1',
  headerName: 'Frequency',
  field: 'frequency',
  cellRendererFramework: 'frequency',
  flex: 1,
}

const stratHybridLink = {
  colId: 'StrategyLink1',
  headerName: 'More info',
  field: 'instrument',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'strategyInfoLink',
  cellStyle: () => ({ cursor: 'pointer' }),
}

const stratHybridColumns = [
  stratHybridInstrumentStrat,
  stratHybridFrequency,
  stratHybridAccuracy,
  stratHybridReturn,
  stratHybridAvgRR,
  stratHybridEvolution,
  stratHybridActivation,
  stratHybridLink,
]

export default stratHybridColumns
