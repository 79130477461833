<template>
  <div
    v-if="params.data"
    class="d-flex flex-row align-items-center"
  >
    <p
      class="action plot"
      @click="plotTrade(params.data)"
    >
      Plot
    </p>
  </div>
</template>

<script>

export default {
  name: 'RendJournalPlotTrade',
  data() {
    return {
      params: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.journalPlotTradeRenderer(this.params)
  },
  methods: {
    journalPlotTradeRenderer(params) {
      this.params = params
    },
    plotTrade(data) {
      this.$router.push({
        name: 'plotTrade',
        params: {
          initialType: 'journal',
          tradeId: data.id,
          initialTrade: data,
        },
      })
    },
  },
}
</script>

<style>
</style>
