<template>
  <div class="d-flex flex-column">
    <span class="cell-line-one"> {{ previous }} / {{ forecast }}</span>
  </div>
</template>

<script>
export default {
  name: 'NewsPreviousForecast',
  data() {
    return {
      previous: null,
      forecast: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.previousForecastRenderer(this.params)
  },
  methods: {
    previousForecastRenderer(params) {
      this.previous = params.data.previous
      this.forecast = params.data.forecast
    },
  },
}
</script>

<style>

</style>
