import accuracyComparator from '../comparator/gridCompareAccuracy'

function avgTpSlRenderer(params) {
  const { pipsTP, pipsSL } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two hermes" >${pipsSL} / ${pipsTP} PIPs</span>
    <span class="cell-line-two" >1 / ${(pipsTP / pipsSL).toFixed(2)}%</span>
  </div>
  `
}

function minMaxTpSl(params) {
  // eslint-disable-next-line object-curly-newline
  const { minTP, maxTP, minSL, maxSL } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two" >TP - ${minTP}/${maxTP}</span>
    <span class="cell-line-two" >SL - ${minSL}/${maxSL}</span>
  </div>
  `
}

function avgMaxNegPos(params) {
  const { avgMaxNeg, avgMaxPos } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two" >Neg - ${avgMaxNeg}</span>
    <span class="cell-line-two" >Pos - ${avgMaxPos}</span>
  </div>
  `
}

function lookAheadBehind(params) {
  const { lookahead, lookbehind, rules } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two" > ${lookahead} / ${lookbehind} DP</span>
    <span class="cell-line-two" >Rules: ${rules}</span>
  </div>
  `
}

function hermesActivation(params) {
  const { avgActivation, stdevActivation } = params.data
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two" >Avg: ${avgActivation.toFixed(2)}%</span>
    <span class="cell-line-two" >Stdev: ${stdevActivation.toFixed(2)}%</span>
  </div>
  `
}

function hermesReturnRenderer(params) {
  const { avgReturn, totalReturn } = params.data
  let textColor = ''
  if (avgReturn > 0) {
    textColor = 'green-text'
  } else {
    textColor = 'red-text'
  }
  return `
  <div class="d-flex flex-column" >
    <span class="cell-line-two ${textColor}" >Avg. Ret: ${avgReturn.toFixed(2)}%</span>
    <span class="cell-line-two ${textColor}" >Total Ret: ${totalReturn.toFixed(2)}%</span>
  </div>
  `
}

const stratHermesAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const stratHermesReturn = {
  colId: 'HermesReturn1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRenderer: hermesReturnRenderer,
}

const stratHermesMinMaxTpSl = {
  colId: 'MinMaxTpSl1',
  headerName: 'Min/Max',
  field: 'maxTP',
  cellRenderer: minMaxTpSl,
  flex: 1,
}
const stratHermesMaxNegPos = {
  colId: 'MaxNegPos1',
  headerName: 'Avg. Max Neg/Pos',
  field: 'MaxNeg',
  cellRenderer: avgMaxNegPos,
  flex: 1,
}
const stratHermesAvgRR = {
  colId: 'AvgRR1',
  headerName: 'Avg. RR',
  field: 'avgTP',
  cellRenderer: avgTpSlRenderer,
  flex: 1,
}
const stratHermesActivation = {
  colId: 'HermesActivation1',
  headerName: 'Activation',
  field: 'avgActivation',
  cellRenderer: hermesActivation,
  flex: 1,
}

const stratHermesInstrumentStrat = {
  colId: 'InstrumentStrategy1',
  headerName: 'Type',
  field: 'instrument',
  cellRendererFramework: 'instrumentStratIndicators',
  flex: 1,
  filter: false,
}
const stratHermesFrequency = {
  colId: 'Frequency1',
  headerName: 'Frequency',
  field: 'frequency',
  cellRendererFramework: 'frequency',
  flex: 1,
}

const stratHermesLookAheadBehind = {
  colId: 'LookAheadBehind1',
  headerName: 'Look Ahead/Behind',
  field: 'lookahead',
  cellRenderer: lookAheadBehind,
  flex: 1,
}

const StratResultPlot = {
  colId: 'Plot1',
  headerName: 'Plot Testing',
  field: 'id',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'strategiesPlot',
}

const stratHermesColumns = [
  stratHermesInstrumentStrat,
  stratHermesFrequency,
  stratHermesAccuracy,
  stratHermesReturn,
  stratHermesMinMaxTpSl,
  stratHermesAvgRR,
  stratHermesMaxNegPos,
  stratHermesActivation,
  stratHermesLookAheadBehind,
  StratResultPlot,
]

export default stratHermesColumns
