<template>
  <div>
    <div
      v-if="entry.user !== 0"
      class="d-flex flex-column"
    >
      <span
        class="cell-line-one"
      >{{ entry.user }}</span>
      <!-- <span
        class="cell-line-two"
      >sig: {{ entry.signal }}</span> -->
    </div>
    <span
      v-else
      class="green-text"
      style="filter: blur(.25rem)"
    >1000000</span>
  </div>
</template>

<script>

export default {
  name: 'RendJournalEntry',
  data() {
    return {
      entry: {},
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.sigEntryRenderer(this.params)
  },
  methods: {
    sigEntryRenderer(params) {
      this.entry = params.data.entryPrice
    },
  },
}
</script>

<style>

</style>
