<template>
  <div>
    <div class="single-strategy-wrap">
      <multipane
        class="trading-pane"
        layout="horizontal"
      >
        <span class="tool-tip">
          <a
            class="tool-tip-link"
            href="#"
            data-beacon-article-sidebar="60ee79c661c60c534bd6f69d"
          />
          <!-- <i class="far fa-question-circle" /> -->
        </span>
        <div class="pane chart-pane">
          <SingleStrategyChart :trades="processedData" />
        </div>
        <multipane-resizer />
        <div
          v-if="$store.state.activeStrategyType !== 'Hermes'"
          class="tab-bar"
        >
          <button
            v-if="$store.state.activeStrategyType !== 'Hermes'"
            class="tab-button"
            @click="selectedTab(0), (activeTab = 0)"
          >
            Trades
          </button>
          <button
            class="tab-button"
            @click="selectedTab(1), (activeTab = 1)"
          >
            Metrics
          </button>
          <button
            v-if="$store.state.activeStrategyType === 'Human'"
            class="tab-button"
            @click="selectedTab(2), (activeTab = 2)"
          >
            About
          </button>
        </div>
        <div
          class="pane grid-pane"
          :style="{ flexGrow: 1 }"
        >
          <b-tabs
            id="full-tabs"
            v-model="activeTab"
            pills
            nav-class="no-nav"
          >
            <b-tab
              v-if="$store.state.activeStrategyType !== 'Hermes'"
              class="full-tab"
              title="Trades"
              active
            >
              <GridSetup
                :processed-data="processedData"
                :pagination="true"
              />
            </b-tab>
            <b-tab
              v-if="$store.state.activeStrategyType !== 'Hermes'"
              class="full-tab"
              title="Charts"
              lazy
            >
              <div class="metric-tab">
                <EChartLine :trades="processedData" />
              </div>
            </b-tab>
            <b-tab
              v-if="$store.state.loading.status !== true"
              class="full-tab"
              title="About"
              lazy
            >
              <div
                v-if="$store.state.activeStrategyType === 'Human'"
                class="about-tab"
              >
                <div
                  v-if="$store.state.single.strategy.info !== null"
                  class="about-strategy"
                >
                  <h2 class="mb-2">
                    {{ $store.state.single.strategy.info.title }}
                  </h2>
                  <vimeo-player
                    v-if="$store.state.single.strategy.info.video !== null"
                    class="mb-2"
                    :video-id="$store.state.single.strategy.info.video"
                  />
                  <p
                    v-if="$store.state.single.strategy.info.about !== null"
                    class="mb-4"
                    v-html="$store.state.single.strategy.info.about.html"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </multipane>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { Multipane, MultipaneResizer } from 'vue-multipane'
import { BTab, BTabs } from 'bootstrap-vue'
// mixins
import instrumentCheck from '@/mixins/instrumentCheck.js'
import toPIPs from '@/mixins/toPIPs.js'

//
import GridSetup from '../../components/agGrid/GridSetup.vue'
import SingleStrategyChart from './StrategySingleChart.vue'
import VimeoPlayer from '../../components/vimeo/VimeoPlayer.vue'

// import TestChart from './TestChart.vue'
import EChartLine from '../../components/echart/EChartBasic.vue'

export default {
  name: 'SingleStrategies',
  components: {
    SingleStrategyChart,
    MultipaneResizer,
    Multipane,
    GridSetup,
    BTabs,
    BTab,
    EChartLine,
    VimeoPlayer,
  },
  directives: { Ripple },
  mixins: [instrumentCheck, toPIPs],
  props: {
    id: {
      type: String,
      default: '',
    },
    instrument: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    strategyType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      strategy: [],
      aboutStrategy: [],
      trades: [],
      activeTab: 0,
    }
  },
  computed: {
    processedData() {
      let data
      if (this.$store.state.single.strategy.data) {
        const storedSingleStratID = this.$store.state.single.strategy.data.stratId
        if (storedSingleStratID !== this.id) {
          this.getSingleStrategy()
        } else {
          data = this.processTrades()
        }
      } else {
        this.getSingleStrategy()
      }
      return data
    },
    strategyInfo() {
      return this.aboutStrategy
    },
  },
  created() {
    this.getHumanStrategyInfo()
  },
  mounted() {
    if (this.$store.state.activeStrategyType !== 'Hermes') {
      this.selectedTab(0)
    }
  },
  methods: {
    processTrades() {
      let data
      if (this.$store.state.activeStrategyType === 'Hermes') {
        // eslint-disable-next-line prefer-destructuring
        data = [this.$store.state.single.strategy.data]
      } else {
        const { instrument, trades, label } = data
        data = trades.map(x => {
          const reward = Math.abs(x.trade.tp / x.trade.sl)
          let strategyType = ''
          if (label.includes('hermes')) {
            strategyType = 'Hermes'
          } else if (label.includes('human')) {
            strategyType = 'Human'
          } else {
            strategyType = 'Hybrid'
          }
          let numberResult
          if (x.revenue > 0) {
            numberResult = parseFloat(reward.toFixed(2))
          } else numberResult = -1
          let action = 'SELL'
          if (x.trade.tp > 0) {
            action = 'BUY'
          }
          return {
            entryPrice: instrumentCheck(instrument, x.entryPrice, 0),
            entryTime: new Date(x.entryTime / 1000),
            entryTimestamp: x.entryTime / 1000,
            sl: instrumentCheck(instrument, x.entryPrice, x.trade.sl),
            tp: instrumentCheck(instrument, x.entryPrice, x.trade.tp),
            reward,
            pipsSL: toPIPs(instrument, x.trade.sl),
            pipsTP: toPIPs(instrument, x.trade.tp),
            exitPrice: instrumentCheck(instrument, x.exitPrice, 0),
            exitTime: x.exitTime,
            exitTimestamp: x.exitTime / 1000,
            strategyType,
            numberResult,
            instrument,
            action,
          }
        })
      }
      return data
    },
    selectedTab(tabId) {
      const tabButtons = document.getElementsByClassName('tab-button')
      tabButtons.forEach(element => {
        element.classList.remove('active')
      })
      // eslint-disable-next-line no-param-reassign
      tabButtons[tabId].className += ' active'
    },
  },
}
</script>

<style lang="scss" >
.single-strategy-wrap {
  position: relative;
  padding-top: 4.55rem;
  align-items: stretch;
  height: 100vh;
  width: 100%;
  .trading-pane {
    position: relative;
    height: 100%;
    width: 100%;
    border-left: 5px solid hsl(0, 0%, 95%);
    border-right: 5px solid hsl(0, 0%, 95%);
    .chart-pane {
      height: 65vh;
      max-height: 100%;
      min-height: 31px;
      &.expanded {
        height: 100% !important;
      }
      &.collapsed {
        height: 31px !important;
      }
    }
    .multipane-resizer {
      background: #e6e6e6;
      z-index: 2;
      height: 5px;
      margin: 0;
      top: 0px;
    }
    .grid-pane {
      z-index: 1;
      max-height: 100%;
      &.expanded {
        height: 100% !important;
      }
      &.collapsed {
        height: 50px !important;
      }
      #full-tabs {
        background-color: rgb(243, 243, 243);
        display: flex;
        width: 100%;
        height: 100%;
        .tab-content {
          width: 100%;
        }
        .full-tab {
          width: 100%;
          height: 100%;
          .metric-tab {
            display: flex;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: scroll !important;
            &::-webkit-scrollbar {
              display: block;
              width: 16px;
            }
          }
        .about-tab {
          display: grid;
          position: relative;
          grid-template-columns: 1fr minmax(300px, 900px) 1fr;
          width: 100%;
          height: 100%;
          overflow-y: scroll !important;
          &::-webkit-scrollbar {
            display: block;
            width: 16px;
          }
          .about-strategy {
            margin-top: 2rem;
            grid-column-start: 2;
            grid-column-end: 2;
            position: absolute;
            width: 100%;
            pre {
              padding: 12px;
              border-radius: 1rem;
              background-color: #fffcf6;
              margin-bottom: 1rem;
            }
          }
        }
        }
      }
    }
  }
}

label {
  font-weight: normal !important;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}

.multipane > div {
  position: initial;
  z-index: initial;
}

// trading page dark layout
.dark-layout {
  .layout-h .multipane-resizer {
    background: #347447;
  }
  .trading-pane {
    border-left: 5px solid hsl(0, 0%, 12%);
    border-right: 5px solid hsl(0, 0%, 12%);
    .multipane-resizer {
      background: #236d3a;
    }
  }
  .metric-tab {
    background-color: hsl(0, 0%, 12%);
  }
}

.no-nav {
  display: none;
}
</style>
