<template>
  <div>
    <span
      v-if="rateLatest"
      class="cell-line-one"
    >{{ entryPrice }} / {{ rateLatest(instrument) }}</span>
    <span
      v-else
      class="green-text"
      style="filter: blur(.25rem)"
    >1000000</span>
  </div>
</template>

<script>
import useRates from '@/composables/rates/useRates'

export default {
  name: 'SigEntry',
  setup() {
    const { rateLatest } = useRates()

    return {
      rateLatest,
    }
  },
  data() {
    return {
      entryPrice: 0,
      instrument: '',
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.sigEntryRenderer(this.params)
  },
  methods: {
    sigEntryRenderer(params) {
      this.entryPrice = params.data.entryPrice
      this.instrument = params.data.instrument
    },
  },
}
</script>

<style>

</style>
