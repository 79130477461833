<template>
  <button
    class="strategy-info-btn"
    @click="navigateSingleStrat(data)"
  >
    More info
  </button>
</template>

<script>
export default {
  name: 'StrategyInfoLink',
  data() {
    return {
      data: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.data = this.params.data
  },
  methods: {
    navigateSingleStrat(data) {
      this.$router.push({
        name: 'strategy',
        params: {
          instrument: data.instrument,
          id: data.id,
          label: data.label,
          strategyType: data.strategyType,
        },
      })
    },
  },
}
</script>

<style>

</style>
