<template>
  <div
    class="d-flex flex-row"
  >
    <button
      :class="buttonMsg + '-action'"
      @click="followUnfollowStrategy()"
    >
      {{ buttonMsg }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'FollowUnfollowStrat',
  data() {
    return {
      buttonMsg: 'follow',
      strategy: {},
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.followStrategyRenderer(this.params)
  },
  methods: {
    followStrategyRenderer(params) {
      this.strategy = params.data
      const currentId = `${this.strategy.label}-${this.strategy.instrument}-${this.strategy.timeframe}`
      const followedStrategyIds = this.$store.state.strategies.followed.ids
      if (followedStrategyIds.includes(currentId)) {
        this.buttonMsg = 'unfollow'
      }
    },
    async followUnfollowStrategy(strategy) {
      const token = await this.$auth.getTokenSilently()
      const followUnfollow = this.doesUserFollow({ type: 'strategy', data: strategy })
      const authId = this.$auth.user.sub
      const options = {
        method: 'POST',
        url: `${process.env.VUE_APP_MLFX_API_BASE}/strategy/${followUnfollow}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        data: {
          strategy,
          authId,
        },
      }
      this.$axios.request(options)
        .then(res => {
          this.$log('info', `User ${authId} ${followUnfollow}d Strategy`, res)
          this.showToast('success', `Strategy ${followUnfollow}d`)
          this.$store.commit('updateFollowedStrategies', strategy)
        })
        .catch(err => {
          this.$log('error', `User ${authId} could not ${followUnfollow} stragety`, err, strategy)
          this.showToast('error', `Error ${followUnfollow}ing strategy`)
        })
    },
  },
}
</script>

<style>

</style>
