<template>
  <div
    v-if="!tradeTaken"
    class="d-flex flex-row align-items-center"
  >
    <p
      :class="'action ' + action.toLowerCase()"
      @click="takeTrade(params.data)"
    >
      TAKE {{ action }}
    </p>
    <!-- <i
      :id="'plotSig' + instrument"
      class="fas fa-wifi plot-signal"
      @click="plotTrade(params.data)"
    /> -->
  </div>
</template>

<script>

export default {
  name: 'RendSignalAction',
  data() {
    return {
      tradeTaken: false,
      timeSinceSignal: null,
      action: null,
      instrument: null,
      params: null,
      maxAge: 5, // minutes

    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.sigActionRenderer(this.params)
  },
  methods: {
    takeTrade(trade) {
      const event = new CustomEvent('take-trade', { detail: trade })
      document.dispatchEvent(event)
    },
    plotTrade(trade) {
      const event = new CustomEvent('plot-trade', { detail: trade })
      document.dispatchEvent(event)
    },
    sigActionRenderer(params) {
      this.action = params.data.action
      this.params = params
      this.instrument = params.data.instrument
      const now = Date.now()
      this.timeSinceSignal = now - params.data.entryTime
    },
  },
}
</script>

<style>
</style>
