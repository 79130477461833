<template>
  <div class="d-flex flex-column">
    <span class="cell-multi-line">{{ date }}</span>
    <span class="cell-multi-line">{{ time }}</span>
  </div>
</template>

<script>
export default {
  name: 'RendAnalysisTime',
  data() {
    return {
      date: null,
      time: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.entryDateTimeRenderer(this.params)
  },
  methods: {
    entryDateTimeRenderer(params) {
      const timestamp = params.data.entryTime

      const date = new Date(timestamp)
      this.date = date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      this.time = date.toLocaleString('en-US', {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
      })
    },
  },
}
</script>

<style>

</style>
