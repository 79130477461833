<template>
  <div class="d-flex flex-row">
    <img
      class="leader-avatar small"
      :src="imgWithTransforms({ path: avatar, type: ['avatar', 'small'] })"
    >
    <div class="d-flex flex-column ml-1">
      <span class="cell-line-one"> {{ name }}</span>
      <span
        class="cell-line-two"
      >{{ rank }} place </span>
    </div>
  </div>
</template>

<script>
import useCloudinary from '@/composables/useCloudinary'

export default {
  name: 'LeaderboardUser',
  setup() {
    const { imgWithTransforms } = useCloudinary()
    return {
      imgWithTransforms,
    }
  },
  data() {
    return {
      name: null,
      rank: null,
      avatar: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.leaderboardUser(this.params)
    this.rankParse(this.params)
  },
  methods: {
    leaderboardUser(params) {
      this.name = params.data.name
      this.avatar = params.data.avatar
    },
    rankParse(params) {
      const { rank } = params.data
      if (rank > 10 && rank < 14) {
        this.rank = `${rank}th`
      } else if (rank === 1 || rank % 10 === 1) {
        this.rank = `${rank}st`
      } else if (rank === 2 || rank % 10 === 2) {
        this.rank = `${rank}nd`
      } else if (rank === 3 || rank % 10 === 3) {
        this.rank = `${rank}rd`
      } else {
        this.rank = `${rank}th`
      }
    },
  },
}
</script>

<style>

</style>
