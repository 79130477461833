<template>
  <div
    id="videoWrap"
  >
    <iframe
      v-if="videoId != null"
      class="vimeo"
      :src="videoUrl"
      width="100%"
      :height="height"
      frameborder="0"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
    />
  </div>
</template>

<script>

export default {
  props: {
    videoId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      height: null,
      videoSize: () => {
        const videoUpdated = document.getElementById('videoWrap')
        this.height = Math.round((videoUpdated.clientWidth / 16) * 9)
      },
    }
  },
  computed: {
    videoUrl() {
      return `https://player.vimeo.com/video/${this.idChange}`
    },
    idChange() {
      return this.videoId
    },
  },
  mounted() {
    const video = document.getElementById('videoWrap')
    this.height = Math.round((video.clientWidth / 16) * 9)
    window.addEventListener('resize', this.videoSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.videoSize)
  },
}
</script>

<style lang="scss">
  #videoWrap {
    width: 100%;
    .vimeo {
      display: block;
    }
  }
</style>
