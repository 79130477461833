<template>
  <div
    v-if="params.data"
    class="close-position"
  >
    <p
      :class="'action ' + action.toLowerCase()"
      @click="closeTrade(params.data)"
    >
      CLOSE {{ action }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'RendPostitionsCloseTrade',
  data() {
    return {
      action: null,
      instrument: null,
      params: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.sigActionRenderer(this.params)
  },
  methods: {
    closeTrade(trade) {
      const event = new CustomEvent('close-trade', { detail: trade })
      document.dispatchEvent(event)
    },
    sigActionRenderer(params) {
      this.params = params
      this.action = params.data.action
      this.instrument = params.data.instrument
    },
  },
}
</script>

<style lang="scss">
  .close-position {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
