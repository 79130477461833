<template>
  <div>
    <div
      v-if="pipsToTP"
      class="d-flex flex-column"
    >
      <span class="cell-line-one">
        <span class="green-text">{{ tp }}</span>
      </span>
      <span class="cell-line-two">{{ pipsToTP }} PIPs away</span>
    </div>
    <div
      v-else
    >
      <span
        class="green-text"
        style="filter: blur(.25rem)"
      >1000000</span>
    </div>
  </div>
</template>

<script>
import useRates from '@/composables/rates/useRates'
import toPIPs from '@/mixins/toPIPs'

export default {
  name: 'PositionsTp',
  setup() {
    const { getLatestRate } = useRates()

    return {
      getLatestRate,
    }
  },
  data() {
    return {
      tp: 0,
    }
  },
  computed: {
    pipsToTP() {
      const latestRate = this.getLatestRate({ instrument: this.params.data.instrument })
      const pips = toPIPs(this.params.data.instrument, Math.abs(this.params.data.tp - latestRate[4]))
      return pips
    },
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.positionsTpRenderer(this.params)
  },
  methods: {
    positionsTpRenderer(params) {
      this.tp = params.data.tp
    },
  },
}
</script>

<style>

</style>
