<template>
  <span>{{ frequency }} signals per week</span>
</template>

<script>
export default {
  name: 'Frequency',
  data() {
    return {
      frequency: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.frequencyData(this.params)
  },
  methods: {
    frequencyData(params) {
      this.frequency = params.data.frequency
    },
  },
}
</script>

<style>

</style>
