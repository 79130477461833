<template>
  <div class="d-flex flex-column">
    <span class="cell-line-one">Total Return: {{ totalReturn }}%</span>
    <span class="cell-line-two">Avg Return: {{ avgReturn }}%</span>
  </div>
</template>

<script>
export default {
  name: 'LeaderboardTotalReturn',
  data() {
    return {
      totalReturn: 0,
      avgReturn: 0,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.leaderboardTotalReturn(this.params)
  },
  methods: {
    leaderboardTotalReturn(params) {
      if (params.data.currentMonth !== null) {
        this.totalReturn = Number(params.data.currentMonth.totalReturn) || 0
        this.avgReturn = Number(params.data.currentMonth.avgReturn) || 0
      }
    },
  },
}
</script>

<style>

</style>
