<template>
  <div
    v-if="metric !== undefined"
    :id="metric + 'ChartWrap'"
    class="chart-wrap"
  >
    <h2 class="chart-title">
      {{ chartTitle }}
    </h2>
    <div
      :id="metric + chartType + 'Chart'"
    />
  </div>
</template>

<script>
/*
Used for:
- Line
- Bar
*/
import * as echarts from 'echarts'

export default {
  props: {
    chartType: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    chartTitle: {
      type: String,
      default: '',
    },
    axisNames: {
      type: Object,
      default: () => {},
    },
    metric: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      myChart: null,
      processedData: [],
      accountSize: 10000, // needs to be more reactive
      chartResize: () => {
        const wrap = document.getElementById(`${this.metric}ChartWrap`)
        this.myChart.resize({
          width: wrap.clientWidth,
          height: (wrap.clientWidth / 16) * 9,
          silent: true,
        })
      },
      test: null,
    }
  },
  computed: {
    chartOptions() {
      return {
        tooltip: {
          show: true,
          trigger: 'item',
          axisPointer: {
            type: 'cross',
          },
        },
        xAxis: {
          type: 'category',
          name: this.axisNames.x,
          namelocation: 'end',
          boundaryGap: false,
          data: [...Array(this.processedData.length + 1).keys()].slice(1),
        },
        yAxis: {
          type: 'value',
          name: this.axisNames.y,
        },
        series: [
          {
            type: this.chartType,
            data: [...this.processedData],
            areaStyle: {},
            labelLayout: {
              align: 'center',
              verticalAlign: 'middle',
            },
          },
        ],
      }
    },
  },
  watch: {
    chartData: {
      handler() {
        this.formatter(this.chartData, this.metric)
        this.drawChart()
      },
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById(`${this.metric}${this.chartType}Chart`))
    const wrap = document.getElementById(`${this.metric}ChartWrap`)
    this.myChart.resize({
      width: wrap.clientWidth,
      height: (wrap.clientWidth / 16) * 9,
      silent: true,
    })
    window.addEventListener('resize', this.chartResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartResize)
  },
  methods: {
    drawChart() {
      this.myChart.setOption(this.chartOptions)
    },
    formatter(metrics, metricType) {
      let format
      let accountSize = 10000
      metrics.forEach(week => {
        if (metricType === 'ROI') {
          const weekReturn = (week.totalReturn / 100) * accountSize
          format = (accountSize + weekReturn).toFixed(2)
          accountSize += weekReturn
        } else {
          format = week[metricType].toFixed(2)
        }
        this.processedData.push(format)
      })
    },
  },
}
</script>

<style lang="scss">

.chart-wrap {
  padding-top: 2rem;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  flex-direction: column;
  .chart-title {
    font-size: 1.4rem !important;
  }
}
.dark-layout {
  .chart-wrap {
    background-color: #1f1f1f;
  }
}
</style>
