const performanceDate = {
  colId: 'PerformanceDate1',
  headerName: 'Date',
  field: 'rawDate',
  flex: 1,
  minWidth: '120',
  cellRendererFramework: 'genericDateTime',
  sort: 'desc',
}

// const performanceInstrument = {
//   colId: 'JournalInstrumentStratAction1',
//   headerName: 'Type',
//   field: 'instrument',
//   cellRendererFramework: 'genericInstrument',
//   flex: 1,
//   filter: false,
// }

const performanceAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  cellRendererFramework: 'accuracyTotalTrades',
}

const performanceReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const performanceNoResult = {
  colId: 'NoResult1',
  headerName: 'No Result',
  field: 'noResult',
  flex: 1,
}

const performanceBuySell = {
  colId: 'BuySell1',
  headerName: 'Buys / Sells',
  cellRendererFramework: 'performanceBuySell',
  field: 'buys',
  flex: 1,
}

const performanceRiskReward = {
  colId: 'RiskReward1',
  headerName: 'Risk/Reward',
  cellRendererFramework: 'riskReward',
  field: 'avgReward',
  flex: 1,
}

const performanceColumns = [
  performanceDate,
  // performanceInstrument,
  performanceAccuracy,
  performanceReturns,
  performanceBuySell,
  performanceRiskReward,
  performanceNoResult,
]

export default performanceColumns
