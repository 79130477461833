import {
  computed,
} from '@vue/composition-api'
import useTabs from '../useTabs'

import singalColumns from '../../views/components/agGrid/layouts/gridLayoutSignals'
import analysisColumns from '../../views/components/agGrid/layouts/gridLayoutAnalysis'
import analysisHermesColumns from '../../views/components/agGrid/layouts/gridLayoutAnalysisHermes'
import journalColumns from '../../views/components/agGrid/layouts/gridLayoutJournal'
import positionsColumns from '../../views/components/agGrid/layouts/gridLayoutPositions'
import stratHumanColumns from '../../views/components/agGrid/layouts/gridLayoutStrategiesHuman'
import stratHybridColumns from '../../views/components/agGrid/layouts/gridLayoutStrategiesHybrid'
import stratHermesColumns from '../../views/components/agGrid/layouts/gridLayoutStrategiesHermes'
import performanceColumns from '../../views/components/agGrid/layouts/gridLayoutPerformance'
import leaderboardColumns from '../../views/components/agGrid/layouts/gridLayoutLeaderboard'
// import newsColumns from '../../views/components/agGrid/layouts/gridLayoutNews'

export default function useAgGrid() {
  const { tabActive, pageName } = useTabs()
  // const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  // const loading = ref(false)

  function setOverlay() {
    let overlay
    if (tabActive.value.name === 'Signals') {
      overlay = `
        <div class="ag-loading-overlay">
          <p class="m-0"> No new signals </p>
        </div>
      `
    } else if (tabActive.value.name === 'Positions') {
      overlay = `
        <div class="ag-loading-overlay">
          <p class="m-0"> No open positions </p>
        </div>
      `
    } else {
      overlay = `
        <div class="ag-loading-overlay">
          <p class="m-0"> Loading </p>
        </div>
      `
    }
    return overlay
  }

  function setColumns() {
    let columns
    if (tabActive.value.name === 'Signals') {
      columns = singalColumns
    } else if (tabActive.value.name === 'Journal') {
      columns = journalColumns
    } else if (tabActive.value.name === 'Positions') {
      columns = positionsColumns
    } else if (pageName.value === 'leaderboard') {
      columns = leaderboardColumns
    } else if (tabActive.value.name === 'Human Strategies') {
      columns = stratHumanColumns
    } else if (tabActive.value.name === 'Hybrid Strategies') {
      columns = stratHybridColumns
    } else if (tabActive.value.name === 'Hermes Strategies') {
      columns = stratHermesColumns
    } else if (tabActive.value.name === 'Human Trades' || tabActive.value.name === 'Hybrid Trades') {
      columns = analysisColumns
    } else if (tabActive.value.name === 'Hermes Trades') {
      columns = analysisHermesColumns
    } else if (pageName.value === 'performance') {
      columns = performanceColumns
    }
    return columns
  }

  return {
    gridOverlay: computed(() => setOverlay()),
    gridColumns: computed(() => setColumns()),
  }
}
