<template>
  <div class="d-flex flex-column">
    <span class="cell-multi-line">{{ date }}</span>
    <span
      v-if="time"
      class="cell-multi-line"
    >{{ time }}</span>
  </div>
</template>

<script>
import useTabs from '@/composables/useTabs'

export default {
  name: 'RendGenericDateTime',
  setup() {
    const { pageName } = useTabs()

    return {
      pageName,
    }
  },
  data() {
    return {
      date: null,
      time: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.entryDateTimeRenderer(this.params)
  },
  methods: {
    entryDateTimeRenderer(params) {
      let timestamp
      if (this.pageName === 'performance') {
        this.date = params.data.date
        this.time = null
      } else {
        timestamp = params.data.entryTime.user || params.data.entryTime
        const date = new Date(timestamp)
        this.date = date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
        this.time = date.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        })
      }
    },
  },
}
</script>

<style>

</style>
