<template>
  <div class="cell-multi-line instrument-grid">
    <span
      v-if="action !== null"
      :class="'action ' + action.toLowerCase()"
      @click="setInstrumentActive({ newInst: { name: instrument } })"
    >
      {{ instrument.replace('_', '/') }} {{ action }}
    </span>
  </div>
</template>

<script>
import useInstruments from '@/composables/useInstruments'

export default {
  name: 'RendGenericInstrument',
  setup() {
    const { setInstrumentActive } = useInstruments()
    return {
      setInstrumentActive,
    }
  },
  data() {
    return {
      instrument: null,
      action: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.genericInstrumentRenderer(this.params)
  },
  methods: {
    genericInstrumentRenderer(params) {
      this.instrument = params.data.instrument
      this.action = params.data.action
    },
  },
}
</script>

<style>

.instrument-grid {
  cursor: pointer;
}
</style>
