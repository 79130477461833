const leaderUser = {
  colId: 'LeaderboardUser1',
  headerName: 'User/Rank',
  cellRendererFramework: 'leaderboardUser',
  field: 'activation',
  flex: 1,
}
const leaderTotalReturn = {
  colId: 'LeaderboardTotalReturn1',
  headerName: 'Current Month',
  cellRendererFramework: 'leaderboardTotalReturn',
  field: 'activation',
  flex: 1,
}
const leaderPrevMonthRets = {
  colId: 'LeaderboardPrevMonthRets1',
  headerName: 'Prev Month',
  cellRendererFramework: 'leaderboardPrevMonthRets',
  field: 'prevMonth',
  flex: 1,
}
const leaderAccuracyTotalTrades = {
  colId: 'LeaderboardAccuracyTotalTrades1',
  headerName: 'Accuracy',
  cellRendererFramework: 'leaderboardAccuracyTotalTrades',
  field: 'activation',
  flex: 1,
}

const leaderColumns = [
  leaderUser,
  leaderTotalReturn,
  leaderPrevMonthRets,
  leaderAccuracyTotalTrades,
]

export default leaderColumns
