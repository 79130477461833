<template>
  <ag-grid-vue
    ref="agGrid"
    style="width: 100%; height: 100%"
    :class="grid.theme"
    :grid-options="gridOptions"
    :column-defs="gridColumns"
    :row-data="gridData"
    :default-col-def="{
      sortable: true,
      resizable: true,
      minWidth: 100,
    }"
    :group-headers="true"
    :suppress-row-click-selection="false"
    :suppress-scroll-on-new-data="true"
    :enable-cell-change-flash="false"
    :overlay-no-rows-template="gridOverlay"
    @grid-ready="onGridReady"
    @pagination-changed="checkPagination"
  />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from 'ag-grid-vue'
// import { ProficiencyFilter } from './proficiencyFilter'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import useTabs from '@/composables/useTabs'
import useStrategies from '@/composables/strategies/useStrategies.js'
import useAgGrid from '@/composables/ag-grid/useAgGrid'
import useInstruments from '@/composables/useInstruments.js'
import usePagination from '@/composables/usePagination.js'
import useTrades from '@/composables/trades/useTrades'

import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'

// renderers
import RiskReward from './renderers/generic/RendGenericRiskReward.vue'
import InstrumentStratIndicators from './renderers/instrumentStratIndicatorsRenderer.vue'
import SigInstrumentTime from './renderers/signals/RendSigInstrumentTime.vue'
import FollowUnfollowStrat from './renderers/FollowUnfollowStratRenderer.vue'
import SigTp from './renderers/signals/RendSigTp.vue'
import SigSl from './renderers/signals/RendSigSl.vue'
import SigEntry from './renderers/signals/RendSigEntry.vue'
import SigAction from './renderers/signals/RendSigAction.vue'
import SigStrategy from './renderers/signals/RendSigStrategy.vue'
import Frequency from './renderers/FrequencyRenderer.vue'
import AccuracyTotalTrades from './renderers/AccuracyTotalTradesRenderer.vue'
import StrategyInfoLink from './renderers/StrategyInfoLinkRenderer.vue'
import RendJournalEntry from './renderers/journal/RendJournalEntry.vue'
import NewsDateTime from './renderers/NewsDateTimeRenderer.vue'
import NewsTitlePair from './renderers/NewsTitlePairRenderer.vue'
import NewsPreviousForecast from './renderers/NewsPreviousForecastRenderer.vue'
import NewsImpact from './renderers/NewsImpactRenderer.vue'

// Performance
import RendPerformanceBuySell from './renderers/performance/RendPerformanceBuySell.vue'

// Strategies
import RendStrategiesPlot from './renderers/strategies/RendStrategiesPlot.vue'
// Signals

// analysis
import RendAnalysisTime from './renderers/analysis/RendAnalysisTime.vue'
import RendAnalysisPlot from './renderers/analysis/RendAnalysisPlot.vue'

// leaderboard
import LeaderboardUser from './renderers/leaderboard/LeaderboardUserRenderer.vue'
import LeaderboardAccuracyTotalTrades from './renderers/leaderboard/LeaderboardAccuracyTotalTradesRenderer.vue'
import LeaderboardTotalReturn from './renderers/leaderboard/LeaderboardTotalReturnRenderer.vue'
import LeaderboardPrevMonthRets from './renderers/leaderboard/LeaderboardPrevMonthRetsRenderer.vue'

// journal
import RendJournalResult from './renderers/journal/RendJournalResult.vue'
import RendJournalPlotTrade from './renderers/journal/RendJournalPlotTrade.vue'

// positions
import RendPositionsTp from './renderers/positions/RendPositionsTp.vue'
import RendPositionsSl from './renderers/positions/RendPositionsSl.vue'
import RendPositionsResult from './renderers/positions/RendPositionsResult.vue'
import RendPositionsCloseTrade from './renderers/positions/RendPositionsCloseTrade.vue'

// Generic
import RendGenericReturns from './renderers/generic/RendGenericReturns.vue'
import RendGenericType from './renderers/generic/RendGenericType.vue'
import RendGenericInstrument from './renderers/generic/RendGenericInstrument.vue'
import RendGenericDateTime from './renderers/generic/RendGenericDateTime.vue'

export default {
  components: {
    AgGridVue,
    // renderers
    instrumentStratIndicators: InstrumentStratIndicators,
    frequency: Frequency,
    // News
    newsDateTime: NewsDateTime,
    newsImpact: NewsImpact,
    newsPreviousForecast: NewsPreviousForecast,
    newsTitlePair: NewsTitlePair,
    // Signals
    sigTp: SigTp,
    sigSl: SigSl,
    sigInstrumentTime: SigInstrumentTime,
    sigEntry: SigEntry,
    accuracyTotalTrades: AccuracyTotalTrades,
    strategyInfoLink: StrategyInfoLink,
    sigAction: SigAction,
    sigStrategy: SigStrategy,
    // Performance
    performanceBuySell: RendPerformanceBuySell,
    // Strategies
    followUnfollowStrat: FollowUnfollowStrat,
    strategiesPlot: RendStrategiesPlot,

    // analysis
    analysisTime: RendAnalysisTime,
    analysisPlot: RendAnalysisPlot,

    // leaderboard
    leaderboardUser: LeaderboardUser,
    leaderboardAccuracyTotalTrades: LeaderboardAccuracyTotalTrades,
    leaderboardTotalReturn: LeaderboardTotalReturn,
    leaderboardPrevMonthRets: LeaderboardPrevMonthRets,

    // Journal
    journalEntryPrice: RendJournalEntry,
    rendJournalResult: RendJournalResult,
    journalPlotTrade: RendJournalPlotTrade,

    // positions
    positionsTP: RendPositionsTp,
    positionsSL: RendPositionsSl,
    positionsResult: RendPositionsResult,
    positionsCloseTrade: RendPositionsCloseTrade,

    // Generic
    genericReturns: RendGenericReturns,
    genericType: RendGenericType,
    genericInstrument: RendGenericInstrument,
    genericDateTime: RendGenericDateTime,
    riskReward: RiskReward,
  },
  props: {
    processedData: {
      type: Array,
      default: () => [],
    },
    searchFilter: {
      type: String,
      default: '',
    },
    pagination: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { tabActive, pageName } = useTabs()
    const { instrumentActive } = useInstruments()
    const { strategyActive } = useStrategies({ instrumentActive })
    const { gridOverlay, gridColumns } = useAgGrid()
    const { updatePagination, paginationCurrent, paginationSize } = usePagination()
    const { getPaginationTrades } = useTrades()

    const gridApi = ref(null)
    const columnApi = ref(null)

    const onGridReady = params => {
      gridApi.value = params.api
      columnApi.value = params.columnApi
    }

    // eslint-disable-next-line arrow-body-style
    const gridOptions = computed(() => {
      return {
        overlayLoadingTemplate: gridOverlay,
        rowSelection: 'multiple',
        pagination: props.pagination,
        getRowClass: params => {
          if (params.node.rowIndex % 2 === 0) {
            return 'even-grid-row'
          }
          return 'odd-grid-row'
        },
      }
    })

    let gridRefreshInterval

    const gridData = computed(() => props.processedData)

    onMounted(() => {
      // if (gridRefreshCell) {
      //   gridRefreshInterval = setInterval(() => {
      //     gridOptions.api.refreshCells(gridRefreshCell)
      //   }, 1000)
      // }
    })

    onUnmounted(() => {
      clearInterval(gridRefreshInterval)
    })

    return {
      tabActive,
      strategyActive,
      instrumentActive,
      gridOptions,
      gridColumns,
      pageName,
      onGridReady,
      gridData,
      gridOverlay,
      //
      updatePagination,
      paginationCurrent,
      paginationSize,
      //
      getPaginationTrades,
    }
  },
  data() {
    return {
      gridApi: null,
      gridColumnApi: null,
      showGrid: false,
      loading: false,
    }
  },
  computed: {
    grid() {
      return this.$isDark.value
        ? {
          theme: 'ag-theme-alpine-dark',
        }
        : {
          theme: 'ag-theme-alpine',
        }
    },
  },
  watch: {
    searchFilter: {
      handler() {
        this.gridOptions.api.setQuickFilter(this.searchFilter)
      },
    },
  },
  beforeMount() {
    this.showGrid = true
  },
  mounted() {
    this.gridOptions.api.paginationGoToPage(this.paginationCurrent)
  },
  methods: {
    async checkPagination() {
      this.gridOptions.api.ensureIndexVisible(0, 'top')
      const total = await this.gridOptions.api.paginationGetTotalPages() - 1
      if (total > 0) {
        // make sure the grid has loaded
        const pageName = this.$route.name
        const current = await this.gridOptions.api.paginationGetCurrentPage()
        if (current !== this.paginationCurrent) {
          // only update page if button is clicked
          this.updatePagination(current)
          const { length } = this.processedData
          if (current === total && length > this.paginationSize - 2) {
            // get data if more stored & last page
            // dispatch fetch
            if (pageName === 'analysis') {
              this.getPaginationTrades()
            }
          }
        }
      }
    },
  },
}
</script>
