<template>
  <div>
    <div
      v-if="tp.latestRate"
      class="d-flex flex-column"
    >
      <span class="cell-line-one">
        <span class="green-text">{{ priceTP }}</span>
        <i
          id="copy"
          class="far fa-copy"
          :data-clipboard-text="priceTP"
        />
      </span>
      <span class="cell-line-two">{{ tp.pipsTP }} PIPs ({{ tp.reward.toFixed(2) }}x risk) </span>
    </div>
    <div
      v-else
      class="d-flex flex-column"
    >
      <span class="cell-line-one">
        <span
          class="green-text"
          style="filter: blur(.25rem);"
        >1000000</span>
        <i
          id="copy"
          class="far fa-copy"
          data-clipboard-text="Nice try 😉"
        />
      </span>
      <span class="cell-line-two">xxx PIPs ({{ tp.reward.toFixed(2) }}x risk) </span>
    </div>
  </div>
</template>

<script>
import usePermissions from '@/composables/usePermissions'
import useRates from '@/composables/rates/useRates'
import toPIPs from '@/mixins/toPIPs'

export default {
  name: 'SigTp',
  setup() {
    const { access } = usePermissions()
    const { getLatestRate } = useRates()

    return {
      access,
      getLatestRate,
    }
  },
  data() {
    return {
      priceTP: 0,
    }
  },
  computed: {
    tp() {
      const latestRate = this.getLatestRate({ instrument: this.params.data.instrument })
      const { sl, tp } = this.params.data
      let pipsTP = 0
      let pipsSL = 0
      let reward = 0

      if (latestRate) {
        pipsTP = toPIPs(this.params.data.instrument, Math.abs(tp - latestRate[4]))
        pipsSL = toPIPs(this.params.data.instrument, Math.abs(sl - latestRate[4]))
        reward = Math.abs(pipsTP / pipsSL)
      }

      return {
        pipsTP,
        tp,
        reward,
        latestRate,
      }
    },
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.sigTpRenderer(this.params)
  },
  methods: {
    sigTpRenderer(params) {
      const access = this.access.signal({ stratType: params.data.strategyType })
      if (access) {
        this.priceTP = params.data.tp
      }
    },
  },
}
</script>

<style>

</style>
