import accuracyComparator from '../comparator/gridCompareAccuracy'
import dateFilterParams from '../filters/gridFilterDate'

function entrySlTpRenderer(params) {
  const { entry, sl, tp } = params.value
  return `
    <div class="d-flex flex-column">
      <span class="cell-multi-line bold green-text" >${tp}</span>
      <span class="cell-multi-line" >${entry}</span>
      <span class="cell-multi-line bold red-text" >${sl}</span>
    </div>
  `
}

const analysisEntryTime = {
  colId: 'AnalysisEntryTime1',
  headerName: 'Date/Time',
  field: 'entryTime',
  filter: 'agDateColumnFilter',
  filterParams: dateFilterParams,
  cellRendererFramework: 'analysisTime',
  flex: 1,
  sort: 'desc',
}

const analysisInstrument = {
  colId: 'JournalInstrumentStratAction1',
  headerName: 'Type',
  field: 'instrument',
  cellRendererFramework: 'genericInstrument',
  flex: 1,
  filter: false,
}

const analysisAccuracy = {
  colId: 'Accuracy1',
  headerName: 'Win %',
  field: 'accuracy',
  flex: 1,
  cellClass: 'accuracy-cell',
  filter: 'agNumberColumnFilter',
  cellRendererFramework: 'accuracyTotalTrades',
  comparator: accuracyComparator,
}

const analysisReturns = {
  colId: 'Returns1',
  headerName: 'Return',
  field: 'avgReturn',
  flex: 1,
  cellRendererFramework: 'genericReturns',
}

const analysisEntrySlTp = {
  colId: 'EntrySlTp1',
  headerName: 'TP/Entry/SL',
  cellRenderer: entrySlTpRenderer,
  sortable: false,
  valueGetter: params => ({
    entry: params.data.entryPrice,
    sl: params.data.sl,
    tp: params.data.tp,
  }),
  flex: 1,
}

const analysisRiskReward = {
  colId: 'RiskReward1',
  headerName: 'Risk/Reward',
  cellRendererFramework: 'riskReward',
  field: 'reward',
  flex: 1,
}

const analysisResultPlot = {
  colId: 'ResultPlot1',
  headerName: 'Result/Plot',
  field: 'result',
  flex: 1,
  resizable: false,
  cellRendererFramework: 'analysisPlot',
}

const analysisColumns = [
  analysisEntryTime,
  analysisInstrument,
  analysisAccuracy,
  analysisReturns,
  analysisEntrySlTp,
  analysisRiskReward,
  analysisResultPlot,
]

export default analysisColumns
