<template>
  <div>
    <div
      v-if="result.user !== null"
      class="d-flex flex-column"
    >
      <span
        :class="'cell-line-one ' + textColor"
      >{{ result.user.toFixed(2) }}%</span>
      <!-- <span
        :class="'cell-line-two ' + textColor"
      >sig: {{ result.signal }}%</span> -->
    </div>
    <span
      v-else
      class="green-text"
    >no result</span>
  </div>
</template>

<script>

export default {
  name: 'RendJournalResult',
  data() {
    return {
      result: {},
      textColor: null,
    }
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    this.rendJournalResutl(this.params)
  },
  methods: {
    rendJournalResutl(params) {
      this.result = params.data.result
      if (params.data.result.user > 0) {
        this.textColor = 'green-text'
      } else {
        this.textColor = 'red-text'
      }
    },
  },
}
</script>

<style>

</style>
