<template>
  <div class="chart-component">
    <div class="chart-top-bar">
      <div class="top-bar-left">
        <button
          class="back-button"
          @click="$router.go(-1)"
        >
          BACK
        </button>
        <b-dropdown
          v-for="instrument in instrumentList"
          :key="instrument.id"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="pairings-dropdown"
          :text="instrument.replace('_', '/')"
        >
          <b-dropdown-item
            :style="{ position: 'relative' }"
            @click="setInstrumentActive({ newInst: instrument })"
          >
            {{ instrument.name }}
          </b-dropdown-item>
        </b-dropdown>
        <div class="chart-option">
          <i
            id="expandGrid"
            class="fas sm fa-arrow-up chart-icon"
            @click="expandGrid"
          />
          <i
            id="expandChart"
            class="fas sm fa-arrow-down chart-icon"
            @click="expandChart"
          />
          <i
            class="fas sm fa-arrow-right chart-icon"
            @click="resetChart"
          />
        </div>
        <p class="m-0 pl-1">
          {{ strategyActive + " " + instrumentActive.replace("_", "/") }}
          strategy
        </p>
        <div
          v-if="strategyActive === 'Hermes'"
          class="datasets"
        >
          <div class="dataset-dot creation" />
          <p class="m-0">
            Creation dataset
          </p>
          <div class="dataset-dot training" />
          <p class="m-0">
            Training dataset
          </p>
        </div>
      </div>
    </div>
    <div
      ref="chartWrap"
      class="chart-wrapper"
      @click="checkRateRange"
      @wheel="checkRateRange"
    >
      <resize-observer @notify="handleResize" />
      <Loader v-if="ratesLoading.blocking" />
      <trading-vue
        v-else
        ref="tradingVue"
        :data="chart"
        :overlays="overlays"
        :color-back="colors.back"
        :color-grid="colors.grid"
        :color-text="colors.text"
        :color-cross="colors.cross"
        :color-candle-up="colors.candle_up"
        :color-candle-dw="colors.candle_dw"
        :color-wick-up="colors.wick_up"
        :color-wick-dw="colors.wick_dw"
        :color-vol-up="colors.volume_up"
        :color-vol-dw="colors.volume_dw"
        :index-based="true"
        :chart-config="config"
        :height="height"
        :width="width"
      />
    </div>
  </div>
</template>

<script>
import { TradingVue } from 'trading-vue-js'
import Ripple from 'vue-ripple-directive'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import useInstruments from '@/composables/useInstruments'
import useStrategies from '@/composables/strategies/useStrategies'
import Loader from '../../components/Loader.vue'
import plotHermesDatasetsOverlay from '../../components/tvchart/overlays/plotHermesDatasetsOverlay'

export default {
  name: 'SingleStrategyChart',
  directives: { Ripple },
  components: {
    TradingVue,
    ResizeObserver,
    BDropdown,
    BDropdownItem,
    Loader,
  },
  setup() {
    const { instrumentActive, instrumentList, setInstrumentActive } = useInstruments()
    const { setStrategyType, strategyList, strategyActive } = useStrategies({ instrumentActive })

    return {
      setInstrumentActive,
      instrumentActive,
      instrumentList,
      setStrategyType,
      strategyList,
      strategyActive,
    }
  },
  props: {
    trades: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
      config: {
        DEFAULT_LEN: 100,
        CANDLEW: 0.5,
        GRIDX: 100,
        GRIDY: 100,
        VOLSCALE: 0.3,
      },
      // eslint-disable-next-line dot-notation
      overlays: [plotHermesDatasetsOverlay],
    }
  },
  computed: {
    instruments() {
      const data = [...this.$store.state.instruments]
      if (data.includes('ALL')) {
        data.pop()
      }
      return data
    },
    indicatorsUsed() {
      let indicators
      if (this.$store.state.single.strategy.data) {
        indicators = this.$store.state.single.strategy.data.name
      }
      return indicators
    },
    chart() {
      if (this.$store.state.rates[this.pair].length === 0 && this.$store.state.activeStrategyType !== 'Hermes') {
        this.$store.dispatch('getRates', this.pair)
      } else if (this.$store.state.rates[this.pair].length === 0 && this.$store.state.activeStrategyType === 'Hermes') {
        this.$store.dispatch('getFromToRates', { page: this.$route.name })
      }
      const data = {
        chart: {
          data: this.$store.state.rates[this.pair],
          tf: '15m',
          grid: {
            logScale: false,
            id: 0,
          },
        },
        onchart: [
          {
            name: 'Trades',
            type: 'tradesOverlay',
            data: [],
            settings: {
              buyColor: '#55c376',
              sellColor: '#f57077',
              isDark: this.$isDark.value,
              'z-index': 10,
            },
          },
          {
            name: 'Datasets',
            type: 'plotHermesDatasetsOverlay',
            data: [],
            settings: {
              buyColor: '#55c376',
              sellColor: '#f57077',
              isDark: this.$isDark.value,
              'z-index': 10,
            },
          },
        ],
        offchart: [],
      }
      return data
    },
    colors() {
      return this.$isDark.value
        ? {
          // dark theme
          back: '#0e0e0e',
          grid: '#292929',
          text: '#35a776',
          cross: '#fff60',
          candle_up: '#236d3a',
          candle_dw: '#673436',
          wick_up: '#236d3a',
          wick_dw: '#673436',
          volume_up: '#57c4783a',
          volume_dw: '#f56e7434',
        }
        : {
          // light theme
          back: '#fff',
          grid: '#eee',
          text: '#333',
          candle_up: '#57C478',
          candle_dw: '#f56e74',
          wick_up: '#57C478',
          wick_dw: '#f56e74',
          volume_up: '#57c4783a',
          volume_dw: '#f56e7434',
        }
    },
    pair() {
      return this.$store.state.activePair
    },
  },
  mounted() {
    // remove ALL as option
    if (this.$store.state.activeStrategyType === 'Hermes') {
      this.expandChart()
    }
    this.instruments.pop()
    this.width = document.getElementsByClassName('chart-wrapper')[0].clientWidth
    this.height = document.getElementsByClassName('chart-wrapper')[0].clientHeight
  },
  methods: {
    checkRateRange() {
      if (this.$refs.tradingVue.$refs.chart.ohlcv.length > 0) {
        const firstVisibleCandleIndex = this.$refs.tradingVue.$refs.chart.sub_start
        if (firstVisibleCandleIndex < 100) {
          this.$store.dispatch('getOldRates', this.pair)
        }
      }
    },
    expandGrid() {
      const gridPane = document.getElementsByClassName('grid-pane')[0]
      const chartPane = document.getElementsByClassName('chart-pane')[0]
      const chartIcon = document.getElementById('expandChart')
      const gridIcon = document.getElementById('expandGrid')

      if (gridPane.classList.contains('expanded')) {
        gridPane.classList.remove('expanded')
        chartPane.classList.remove('collapsed')
        gridIcon.classList.remove('clicked')
      } else {
        gridPane.classList.add('expanded')
        gridPane.classList.remove('collapsed')
        chartPane.classList.add('collapsed')
        chartPane.classList.remove('expanded')
        chartIcon.classList.remove('clicked')
        gridIcon.classList.add('clicked')
      }
    },
    expandChart() {
      const gridPane = document.getElementsByClassName('grid-pane')[0]
      const chartPane = document.getElementsByClassName('chart-pane')[0]
      const chartIcon = document.getElementById('expandChart')
      const gridIcon = document.getElementById('expandGrid')

      if (chartPane.classList.contains('expanded')) {
        chartPane.classList.remove('expanded')
        gridPane.classList.remove('collapsed')
        chartIcon.classList.remove('clicked')
      } else {
        gridPane.classList.add('collapsed')
        gridPane.classList.remove('expanded')
        chartPane.classList.add('expanded')
        chartPane.classList.remove('collapsed')
        chartIcon.classList.add('clicked')
        gridIcon.classList.remove('clicked')
      }
    },
    resetChart() {
      if (this.$refs.tradingVue) {
        this.$refs.tradingVue.resetChart()
      }
    },
    handleResize({ width, height }) {
      this.width = width
      this.height = height
    },
  },
}
</script>

<style lang="scss">
.trading-graph {
  cursor: crosshair;
}

.datasets {
  display: flex;
  align-items: center;
  .dataset-dot {
    margin-left: 1rem;
    margin-right: 0.4rem;
    padding: 6px;
    border-radius: 100rem;
    &.creation {
      background-color: rgba(39, 63, 245, 0.08);
    }
    &.training {
      background-color: rgba(245, 39, 243, 0.08);
    }
  }
}

.single-active-pair {
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 13px;
  font-weight: 600;
}
.back-button {
  border: none;
  padding: 2px 8px 2px 8px;
  margin-left: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #f0f0f0;
  border-radius: 20rem;
  background-color: #28c870;
}

.resize-observer {
  width: 100%;
  height: 100%;
}
.chart-component {
  width: 100%;
  height: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  .chart-top-bar {
    display: flex;
    align-items: center;
    background: white;
    width: 100%;
    height: 32px;
    border-bottom: 1px solid rgb(240, 240, 240);
    z-index: 90;
    .pairings-dropdown {
      border-right: 1px solid #f0f0f0;
      border-radius: 0;
      ::after {
        margin-left: 8px;
        padding: 1px 0 2px 0;
        border-radius: 20rem;
        background-color: #625f6d !important;
      }
      .btn-secondary {
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent !important;
        color: black !important;
        border-radius: 0 !important;
        padding: 8px 10px 8px 10px;
        font-size: 14px;
      }
      .dropdown-menu {
        margin-left: 4px;
        &.show {
          position: absolute;
          z-index: 100;
        }
      }
    }
    .chart-option {
      cursor: pointer;
      height: 100%;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      .chart-icon {
        padding: 8px 9px 8px 9px;
        border-right: 1px solid rgb(240, 240, 240);
        &.clicked {
          color: rgb(5, 206, 88);
        }
      }
    }
  }
  .chart-wrapper {
    width: 100%;
    height: calc(100% - 32px);
    display: flex;
    position: relative;
  }
}
.pairings-dropdown-content {
  position: relative;
  z-index: 3333;
}
.trading-vue-chart {
  position: relative;
  height: 100%;
  .trading-vue-legend {
    max-width: 45% !important;
    z-index: 1;
    .t-vue-title {
      display: none;
    }
  }
  .t-vue-ind {
    display: none;
  }
  .trading-vue-botbar {
    #trading-vue-js-botbar-canvas {
      height: 100%;
    }
  }
}

.dark-layout {
  .chart-top-bar {
    background: #0e0e0e;
    border-bottom: 1px solid hsl(0, 0%, 21%);
    .chart-option {
      .chart-icon {
        border-color: hsl(0, 0%, 21%);
        &.clicked {
          color: rgb(46, 139, 85);
        }
      }
    }
    .pairings-dropdown {
      border-right: 1px solid hsl(0, 0%, 21%);
      ::after {
        background-color: hsl(0, 0%, 21%) !important;
      }
      .btn-secondary {
        color: rgb(219, 219, 219) !important;
      }
    }
  }
}
</style>
