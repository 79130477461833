<template>
  <div
    class="d-flex flex-column"
  >
    <span class="cell-line-one">{{ result.movement.toFixed(2) }}%</span>
    <span class="cell-line-two">{{ result.profit }}</span>
  </div>
</template>

<script>
import toPIPs from '@/mixins/toPIPs'
import useRates from '@/composables/rates/useRates'
import useJournalTrades from '@/composables/journal/useJournalTrades'

export default {
  name: 'PositionsResult',
  setup() {
    const { journalAccount } = useJournalTrades()
    const { getLatestRate } = useRates()

    return {
      getLatestRate,
      journalAccount,
    }
  },
  computed: {
    result() {
      const latestRate = this.getLatestRate({ instrument: this.params.data.instrument })
      let movement
      const sl = this.params.data.pipsSL.user
      const current = toPIPs(this.params.data.instrument, Math.abs(this.params.data.entryPrice.user - latestRate[4]))
      if (this.params.data.action === 'BUY' && latestRate[4] > this.params.data.entryPrice.user) {
        // calculate movement towards TP
        movement = current / sl
      } else if (this.params.data.action === 'SELL' && latestRate[4] < this.params.data.entryPrice.user) {
        // calculate movement towards TP
        movement = current / sl
      } else {
        // movement is towards SL
        movement = -current / sl
      }

      const profitLoss = (this.journalAccount * (movement / 100)).toFixed(2)
      let profit

      if (profitLoss < 0) {
        profit = `-$${profitLoss.replace('-', '')}`
      } else profit = `+$${profitLoss.replace('-', '')}`

      return {
        movement,
        profit,
      }
    },
  },
}
</script>

<style>

</style>
